import Group from "../../../../../../components/arrangement/Group/Group";
import Stack from "../../../../../../components/arrangement/Stack/Stack";
import ButtonV2 from "../../../../../components/atoms/Button/ButtonV2";
import ListItem from "../../../../../components/ListItem/ListItem";
import { ISectionStack } from "../ISectionStack";

import "./SectionStack.scss";

const SectionStack = ({ description, itemsList, title, button }: ISectionStack) => {
  return (
    <Stack className="section-stack" rowGap={20}>
      <h3>{title}</h3>
      <Stack fullWidth rowGap={40}>
        <p className="s2">{description}</p>
        {!!itemsList && itemsList?.length > 0 && (
          <Stack rowGap={20}>
            {itemsList.map((el, id) => (
              <ListItem {...el} key={id} />
            ))}
          </Stack>
        )}
        <a href={button.url} className={button.isDisabled ? "disabled" : ""}>
          <ButtonV2 size={"md"} hierarchy={"secondary"} isDisabled={button.isDisabled}>
            <Group fullSize justifyContent="center" colGap={8}>
              <p className="s2 primary" style={{ fontSize: "18px" }}>
                See how it works
              </p>
              <img src="/images/icons/watch-yt-30.svg" alt="watch on yt" />
            </Group>
          </ButtonV2>
        </a>
      </Stack>
    </Stack>
  );
};

export default SectionStack;
